@font-face {
    font-family: 'franklin-gothic-heavy';
    src: url('./fonts/franklin-gothic-heavy.ttf');
}

@font-face {
    font-family: 'franklin-gothic-demi';
    src: url('./fonts/franklin-gothic-demi.ttf');
}

@font-face {
    font-family: 'franklin-gothic-book';
    src: url('./fonts/franklin-gothic-book.ttf');
}

* {
    padding: 0;
    margin: 0;
}

body {
    overflow-x: hidden;
    background: black;
    height: 100%;
    font-size: 100%;
}

a {
    text-decoration: none;
    color: #b36731;
    font-family: 'franklin-gothic-demi';
}

a:hover {
    color: rgb(65, 139, 139);
}

h2 {
    font-size: 1.5em;
    font-family: 'franklin-gothic-heavy';
    color: black;
    cursor: default;
}

h4 {
    text-align: center;
    font-family: 'franklin-gothic-demi';
}


p {
    color: black;
    font-family: 'franklin-gothic-book' !important;
    cursor: default;
    font-size: 16px !important;
}

.spacer {
    width: 100px;
    background-color: white;
    height: 5px;
    margin: 13px 0px 13px 0px;
}

.spacer-black {
    width: 100px;
    background-color: black;
    height: 5px;
    margin: 10px 0px 15px 0px;
}

.spacer-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.pointer {
    cursor: pointer;
}

.animateIn3 {
    animation: bounceInUp;
    animation-duration: 3s;
}

.animateIn2 {
    animation: bounceInUp;
    animation-duration: 2s;
}

/* HEADER STYLE */
header {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    min-width: 280px;
    animation: bounceInLeft;
    animation-duration: 2s; 
    margin-top: 10px;
}

.head-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid white 4px;
    border-radius: 15px;
    padding: 0px 10px;
    margin-bottom: 4px;
    margin-top: 2px;
    margin-left: -49px;
    padding-left: 50px;
    position: relative;
    z-index: 0;
}

h1 {
    color: white;
    font-size: 20px;
    line-height: 35px;
    font-family: 'franklin-gothic-heavy';
    cursor: default;
}

/* LOGO STYLE */
.logo {
    width: 75px;
    margin-right: 10px;
    position: relative;
    z-index: 1;
}


/* MAIN WRAPPER */
.main-wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    margin-top: 20px;
}


/* DROPDOWN STYLE */
select {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 20px;
    color: black;
    width: 160px;
    height: 40px;
    font-family: 'franklin-gothic-book';
    padding-left: 5px;
    border-radius: 10px;
    text-align: center;
    transition: all 0.5s;
}

/* BUTTON STYLE */
h3 {
    color: white;
    font-size: 18px;
    font-family: 'franklin-gothic-heavy';
    cursor: default;
    text-align: center;
}

h5 {
    text-align: center;
    font-family: 'franklin-gothic-book';
    color: white;
    cursor: default;
    text-align: center;
    font-size: 14px;
}

.option-text-wrapper {
    margin-bottom: 8px;
    text-align: center;
}

.option-text-wrapper a {
    font-family: 'franklin-gothic-book';
    font-size: 14px;
    text-align: center;
}

.button-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mats {
    width: 150px;
}

.arrow-wrapper {
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    font-size: 50px;
}

.option {
    margin-top: 10px;
}

.options-main-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.option-wrapper {
    margin: 10px;
    animation: fadeInUp;
    animation-duration: 1.5s;
}

.button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.btn {
    display:inline-block;
    margin:-2px;
    padding: 4px 12px;
    margin-bottom: 0;
    font-size: 20px;
    font-family: 'franklin-gothic-heavy';
    line-height: 20px;
    color: #333;
    text-align: center;
    text-shadow: 0 1px 1px rgba(255,255,255,0.75);
    vertical-align: middle;
    cursor: pointer;
    background-color: #f5f5f5;
    background-repeat: repeat-x;
    border: 1px solid #ccc;
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1) rgba(0,0,0,0.25);
    border-bottom-color: #b3b3b3;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff',endColorstr='#ffe6e6e6',GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,0.2),0 1px 2px rgba(0,0,0,0.05);
    -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,0.2),0 1px 2px rgba(0,0,0,0.05);
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.2),0 1px 2px rgba(0,0,0,0.05);
    height: 50px;
    border-radius: 10px;
}

.btn:hover {
    color: white;
    background-color: #b36731;
}

.reset-btn {
    background: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.reset-btn:hover {
    color: #b36731;
}

.reset {
    position: absolute;
    bottom: 10px;
    right: 10px;
}


/* PERGOLA STYLE */

.pergola-wrapper {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.pergola {
    width: 100%;
}



/* INFO PANEL */
.info-panel {
    background-color: white;;
    height: auto;
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-bottom: 50px;
    margin-top: 100px;
}

.info-head {
    margin-top: 30px;
}

.info-info {
    list-style-type: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.info-info-item {
    display: inline-block;
    width: 240px;
    border-collapse: collapse;
    margin: 40px 35px;
    font-size: 20px;
    font-family: 'franklin-gothic-book';
    box-shadow: 0px 0px 20px black;
    background: white;
    border-radius: 20px;
    cursor: default;
    padding: 30px
}

.info-info-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
}

.info-wrapper {
    display: flex;
    justify-content: center;
}

.disclaimer {
    width: 300px;
}

table {
    border-collapse: collapse;
    margin: 25px 0px;
    width: 300px;
    font-size: 20px;
    font-family: 'franklin-gothic-book';
    box-shadow: 0px 0px 20px black;
    background: white;
    border-radius: 20px;
    cursor: default;
}

.table-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
}

.table-wrapper-sub {
    margin: 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

table, th, tr, td{
    color: black;
    padding: 10px;
    text-align: center;
}

td {
    border-top: 1px solid black;
}

th {
    font-family: 'franklin-gothic-demi';
    font-size: 30px;
    border-bottom: 5px solid black;
}

tr {
    height: 65px;
}

.cost-input {
    width: 50px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}

.lumdim {
    font-size: 18px;
    font-style: italic;
    font-family: 'franklin-gothic-demi';
}

.cost {
    font-size: 18px;
    font-family: 'franklin-gothic-demi';
    margin-bottom: 10px;
}

.total-cost {
    font-size: 18px;
    font-family: 'franklin-gothic-demi';
    margin-bottom: 10px;
}



/* FOOTER */
.footer {
    height: 70px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    position: relative;
    z-index: 10;
    border-top: 3px solid black;
}

@media (min-width: 360px) {
    h1 {
        font-size: 28px;
        line-height: 35px;
    }
}

@media (min-width: 450px) {
    table {
        width: 400px;
    }

    .total-cost {
        font-size: 25px;
        margin-bottom: 30px;
    }

    .info-info-item {
        width: 340px;
    }

    .disclaimer {
        width: 400px;
    }
}

@media (min-width: 900px) {
    .main-wrapper {
        flex-direction: row;
    }

    .button-group {
        margin: 50px;
        order: -1;
    }

    .table-wrapper {
        flex-direction: row;
    }

    header {
        justify-content: left;
        margin-left: 20px;
        margin-top: 20px;
    }

    h1 {
        font-size: 40px;
        line-height: 40px;
    }
    h2 {
        font-size: 35px;
    }

    .reset {
        top: 10px;
        left: 10px;
    }

    .options-main-wrapper {
        flex-direction: column;
    }

    .disclaimer {
        width: 800px;
    }

    .info-info-wrapper {
        flex-direction: row;
    }

}

@media (max-width: 359px) {
    .options-main-wrapper {
        flex-direction: column;
    }
}